import React from "react"
import styled, { css } from "styled-components"

import UseCasesImage from "../../../utils/assets/UseCasesImage"
import { respH1Font, respH2Font } from "../../../utils/sharedStyles"

const sharedStyles = css`
  margin: 10px 0;
  text-align: left;
`

const StyledH1 = styled.h1`
  ${sharedStyles}
  ${respH1Font}
`

const StyledH2 = styled.h2`
  ${sharedStyles}
  ${respH2Font}
`

const StyledUseCasesImage = styled(UseCasesImage)`
  margin: 0 auto;
  width: 90%;
`

const FirstPanel = () => {
  return (
    <>
      <StyledH1>Use Cases</StyledH1>
      <StyledH2>
        Approximately two and a half exabytes of unstructured data are created
        daily on the internet right now. To put this into perspective, the
        amount of unstructured data that was created in the past two days is
        equivalent to the same amount of data that was created from the
        beginning of humankind through the end of 2003.
      </StyledH2>
      <StyledUseCasesImage />
    </>
  )
}

export default FirstPanel
