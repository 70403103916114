import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/shared/Seo"
import FirstPanel from "../components/UseCases/FirstPanel/FirstPanel"
import SecPanel from "../components/UseCases/SecPanel/SecPanel"
import PanelContainer from "../components/shared/PanelContainer"

const UseCases = () => (
  <Layout>
    <SEO title="Use Cases" />
    <PanelContainer>
      <FirstPanel />
    </PanelContainer>
    <PanelContainer>
      <SecPanel />
    </PanelContainer>
  </Layout>
)

export default UseCases
