import React from "react"
import styled, { css } from "styled-components"

import { respTextFont, respH4Font } from "../../../utils/sharedStyles"

const StyledH4 = styled.h4`
  ${respH4Font}
  margin: 10px 0;
`

const sharedStyled = css`
  ${respTextFont}
`

const StyledTitle = styled.p`
  ${sharedStyled}
`

const StyledOl = styled.ol`
  ${sharedStyled}
`

const StyledLi = styled.li``

const SecPanel = () => {
  return (
    <>
      <StyledH4>
        Around 80% of all available data over the internet are unstructured and
        the growth rate gap between unstructured and structured data is only
        widening. Humans have two ways to process, understand, and harness the
        informational content of these vast, relatively untapped content sets –
        manual processing, which is infeasible, or automatic processing, where
        NLP comes into play.
      </StyledH4>
      <StyledTitle>How can NLP help you?</StyledTitle>
      <StyledOl>
        <StyledLi>
          Insights from Earning Calls. Analysis of the historical relationship
          between a stock’s sentiment changes vs. its forward returns. The other
          use case is creating a heat-map of industry-level sentiment trends.
        </StyledLi>
        <StyledLi>
          Customer Service. Automation tickets classification all incoming
          customers’ requests. Rapidly detect disgruntled customers and surface
          those tickets to the top. There is also a possibility to quickly
          discover new trends in customers’ questions.
        </StyledLi>
        <StyledLi>
          Social Networks Monitoring. Analyze Tweets and/or Facebook posts over
          a period of time to detect the sentiment of a particular audience.
          Automatically route social media mentions to team members best fit to
          response.
        </StyledLi>
        <StyledLi>
          Creditworthiness Assessment. Banks in developing countries can assess
          the scoring of clients with no credit history based on customers’
          digital footprints. NLP can analyze geo data, social media activity,
          browsing behavior and generate a credit score highly predictive of
          customer further activity.
        </StyledLi>
        <StyledLi>
          E-commerce Reviews. Reviews have the power to gain customer trust, and
          they encourage people to interact with the company. Massive analysis
          of reviews can also help understand trends and improve products from
          users’ feedback.
        </StyledLi>
      </StyledOl>
    </>
  )
}

export default SecPanel
