import React from "react"
import styled from "styled-components"

const StyledPanelContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 2.4vw;
  width: 50%;
  align-items: stretch;

  ${({ theme }) => theme.media.phone} {
    width: 100%;
  }
`

const PanelContainer = props => {
  return (
    <StyledPanelContainer {...props}>{props.children}</StyledPanelContainer>
  )
}

export default PanelContainer
