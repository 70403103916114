import React from "react"

const UseCasesImage = props => {
    return (
    <svg
      width={661.359}
      height={360.172}
      viewBox="0 0 661.359 360.172"
      {...props}
    >
      <defs>
        <filter
          id="a"
          x={255.829}
          y={0.001}
          width={280.02}
          height={288.866}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={2} result="blur" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="b"
          x={0}
          y={94.276}
          width={220.368}
          height={237.088}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={3} result="blur-2" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="c"
          x={457.043}
          y={73.592}
          width={131.371}
          height={141.914}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={2} result="blur-3" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="d"
          x={108.581}
          y={73.477}
          width={221.72}
          height={229.564}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={2} result="blur-4" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-4" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="e"
          x={144.364}
          y={3.709}
          width={229.168}
          height={249.97}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={3} result="blur-5" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-5" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="f"
          x={441.145}
          y={87.866}
          width={220.213}
          height={230.032}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={3} result="blur-6" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-6" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="g"
          x={58.331}
          y={204.83}
          width={100.633}
          height={110.355}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={2} result="blur-7" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-7" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="h"
          x={167.041}
          y={71.305}
          width={280.02}
          height={288.866}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={2} result="blur-8" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-8" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="i"
          x={440.991}
          y={0}
          width={220.368}
          height={237.088}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={3} result="blur-9" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-9" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="j"
          x={69.946}
          y={112.86}
          width={131.371}
          height={141.914}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={2} result="blur-10" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-10" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="k"
          x={328.058}
          y={25.324}
          width={221.72}
          height={229.564}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={2} result="blur-11" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-11" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="l"
          x={353.034}
          y={99.155}
          width={229.168}
          height={249.97}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={3} result="blur-12" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-12" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="m"
          x={0.001}
          y={13.466}
          width={220.213}
          height={230.032}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={3} result="blur-13" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-13" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="n"
          x={478.72}
          y={13.18}
          width={100.633}
          height={110.355}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={20} dy={30} />
          <feGaussianBlur stdDeviation={2} result="blur-14" />
          <feFlood floodColor="#fff" floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-14" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 141">
        <g
          filter="url(#a)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g
            data-name="Polygon 15"
            fill="none"
            strokeLinejoin="round"
            opacity={0.999}
          >
            <path
              d="M87.682 13.727a9 9 0 0115.64 0l80.017 140.455a9 9 0 01-7.82 13.455H15.485a9 9 0 01-7.82-13.455z"
              transform="rotate(-43 293.27 -259.596)"
            />
            <path
              d="M95.502 13.182c-.869 0-1.718.217-2.457.63a4.91 4.91 0 00-1.887 1.895L11.14 156.162c-1.286 2.256-.413 4.24.021 4.987.435.748 1.726 2.488 4.323 2.488H175.52c2.597 0 3.888-1.74 4.323-2.488.435-.748 1.307-2.73.021-4.987L99.847 15.707a4.91 4.91 0 00-1.888-1.896 5.053 5.053 0 00-2.457-.63m0-4c3.047 0 6.094 1.516 7.82 4.546l80.017 140.455c3.418 6-.915 13.455-7.82 13.455H15.485c-6.905 0-11.238-7.455-7.82-13.455L87.682 13.727c1.726-3.03 4.773-4.545 7.82-4.545z"
              fill="#ff4a3c"
              transform="rotate(-43 293.27 -259.596)"
            />
          </g>
        </g>
        <g
          filter="url(#b)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g
            data-name="Polygon 16"
            fill="none"
            strokeLinejoin="round"
            opacity={0.999}
          >
            <path
              d="M68.042 13.753a9 9 0 0115.646 0l60.391 106.169a9 9 0 01-7.823 13.45H15.474a9 9 0 01-7.823-13.45z"
              transform="rotate(-120 122.332 124.278)"
            />
            <path
              d="M75.865 13.203c-.87 0-1.72.218-2.458.63a4.91 4.91 0 00-1.888 1.898L11.127 121.9c-1.283 2.256-.41 4.238.024 4.986.435.747 1.727 2.486 4.323 2.486h120.783c2.595 0 3.887-1.739 4.322-2.486.434-.748 1.307-2.73.024-4.986L80.21 15.73a4.91 4.91 0 00-1.887-1.897 5.053 5.053 0 00-2.459-.63m0-4c3.049 0 6.097 1.516 7.823 4.55l60.391 106.169c3.413 6-.92 13.45-7.822 13.45H15.474c-6.903 0-11.236-7.45-7.823-13.45l60.391-106.17c1.726-3.033 4.774-4.55 7.823-4.55z"
              fill="#3fe0e0"
              transform="rotate(-120 122.332 124.278)"
            />
          </g>
        </g>
        <g
          filter="url(#c)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g data-name="Path 470" fill="none" opacity={0.999}>
            <path
              d="M19.9 10.113c7.43-13.98 27.225-13.342 35.538 1.146L74.994 45.34c8.272 14.416-1.037 31.75-16.779 31.243l-37.03-1.194C5.444 74.881-4.963 56.912 2.428 43z"
              transform="rotate(120 248.905 218.299)"
            />
            <path
              d="M36.689 4c-2.698 0-5.31.685-7.552 1.98-2.403 1.39-4.322 3.411-5.703 6.01L5.96 44.877c-1.442 2.714-2.094 5.67-1.938 8.784.148 2.948 1.037 5.907 2.573 8.558 1.535 2.65 3.658 4.892 6.141 6.486 2.623 1.683 5.509 2.587 8.578 2.686l37.03 1.194c.184.006.362.009.544.009 2.802 0 5.384-.696 7.672-2.068 2.145-1.286 3.968-3.157 5.273-5.409 1.457-2.516 2.233-5.426 2.244-8.415.011-3.2-.871-6.44-2.552-9.371L51.969 13.25c-1.69-2.945-4.055-5.35-6.84-6.957-2.6-1.5-5.52-2.293-8.44-2.293m0-4c7.123 0 14.462 3.788 18.75 11.26l19.555 34.08c8.153 14.209-.776 31.254-16.106 31.254-.224 0-.446-.004-.673-.011l-37.03-1.194C5.444 74.881-4.963 56.912 2.428 43l17.474-32.887C23.5 3.343 29.999 0 36.689 0z"
              fill="#ffcd43"
              transform="rotate(120 248.905 218.299)"
            />
          </g>
        </g>
        <g
          filter="url(#d)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g data-name="Path 471" fill="none" opacity={0.999}>
            <path
              d="M40.9 20.783c15.265-28.73 55.944-27.418 73.028 2.355l40.187 70.036c17 29.625-2.132 65.247-34.481 64.2l-76.1-2.454C11.186 153.881-10.2 116.953 4.99 88.366z"
              transform="matrix(-.97 .26 -.26 -.97 304.3 225.51)"
            />
            <path
              d="M75.396 4c-6.284 0-12.38 1.603-17.63 4.637a33.74 33.74 0 00-7.411 5.804c-2.302 2.389-4.295 5.154-5.924 8.219L8.522 90.243a38.565 38.565 0 00-3.654 9.99 39.421 39.421 0 00-.817 10.25c.336 6.727 2.359 13.467 5.85 19.492 3.489 6.024 8.328 11.13 13.992 14.765a39.396 39.396 0 009.294 4.387 38.54 38.54 0 0010.478 1.8l76.096 2.453c.423.013.834.02 1.255.02 3.307 0 6.537-.43 9.601-1.279a33.7 33.7 0 008.335-3.572c5.02-3.01 9.279-7.373 12.317-12.619 3.363-5.808 5.154-12.514 5.18-19.393a41.152 41.152 0 00-1.381-10.676c-.974-3.679-2.462-7.278-4.424-10.696l-40.187-70.037c-1.972-3.436-4.34-6.55-7.041-9.258a41.162 41.162 0 00-8.568-6.594C88.864 5.824 82.138 4 75.396 4m0-4c14.638 0 29.72 7.784 38.53 23.137l40.187 70.037c16.755 29.198-1.594 64.226-33.097 64.226-.46 0-.918-.007-1.384-.022l-76.096-2.454c-32.35-1.043-53.736-37.97-38.546-66.558l35.909-67.583C48.292 6.869 61.649 0 75.396 0z"
              fill="#ffcd43"
              transform="matrix(-.97 .26 -.26 -.97 304.3 225.51)"
            />
          </g>
        </g>
        <g
          filter="url(#e)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g
            data-name="Polygon 17"
            fill="none"
            strokeLinejoin="round"
            opacity={0.999}
          >
            <path
              d="M78.472 3.362a9 9 0 0110.026 0l72.68 48.738a9 9 0 013.477 10.463l-27.457 78a9 9 0 01-8.489 6.012H38.262a9 9 0 01-8.489-6.012l-27.457-78A9 9 0 015.792 52.1z"
              transform="rotate(-67 191.118 -30.194)"
            />
            <path
              d="M83.485 5.837c-.995 0-1.958.293-2.785.847L8.02 55.426A4.999 4.999 0 006.09 61.24l27.456 78.001a5.007 5.007 0 004.717 3.34h90.446a5.007 5.007 0 004.717-3.34L160.88 61.24a4.999 4.999 0 00-1.931-5.813L86.27 6.684a4.983 4.983 0 00-2.785-.847m0-4a8.99 8.99 0 015.013 1.525l72.68 48.742a9 9 0 013.476 10.463l-27.456 78.001a9 9 0 01-8.49 6.012H38.262a9 9 0 01-8.49-6.012l-27.456-78a9 9 0 013.476-10.464l72.68-48.742a8.99 8.99 0 015.013-1.525z"
              fill="#3f7de0"
              transform="rotate(-67 191.118 -30.194)"
            />
          </g>
        </g>
        <g
          filter="url(#f)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g
            data-name="Polygon 18"
            fill="none"
            strokeLinejoin="round"
            opacity={0.999}
          >
            <path
              d="M65.835 1.981a9 9 0 017.994 0l48.147 23.867a9 9 0 014.789 6.115l11.985 54.051a9 9 0 01-1.672 7.46l-33.466 43.2a9 9 0 01-7.115 3.488H43.166a9 9 0 01-7.115-3.488L2.586 93.475a9 9 0 01-1.672-7.46L12.9 31.963a9 9 0 014.789-6.115z"
              transform="rotate(-120 336.001 -8.057)"
            />
            <path
              d="M69.832 5.045c-.766 0-1.534.18-2.22.52L19.465 29.432a4.978 4.978 0 00-2.66 3.397L4.818 86.88a4.986 4.986 0 00.93 4.145l33.465 43.196a5.033 5.033 0 003.952 1.938h53.332a5.033 5.033 0 003.953-1.938l33.465-43.196a4.986 4.986 0 00.93-4.145L122.86 32.83a4.978 4.978 0 00-2.66-3.398L72.052 5.565a5.026 5.026 0 00-2.22-.52m0-4c1.368 0 2.737.312 3.996.936l48.147 23.867a9 9 0 014.79 6.115l11.984 54.051a9 9 0 01-1.672 7.46l-33.465 43.197a9 9 0 01-7.115 3.488H43.166a9 9 0 01-7.114-3.488L2.586 93.475a9 9 0 01-1.672-7.46L12.9 31.962a9 9 0 014.79-6.115L65.834 1.981a8.996 8.996 0 013.997-.936z"
              fill="#3fe0e0"
              transform="rotate(-120 336.001 -8.057)"
            />
          </g>
        </g>
        <g
          filter="url(#g)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g data-name="Path 472" fill="none" opacity={0.999}>
            <path
              d="M13.81 7.017c5.154-9.7 18.889-9.258 24.658.8L52.037 31.46c5.74 10-.72 22.031-11.643 21.679L14.7 52.31C3.777 51.958-3.444 39.489 1.685 29.837z"
              transform="rotate(146 28.433 144.77)"
            />
            <path
              d="M25.458 4C23.8 4 22.2 4.419 20.83 5.21c-1.466.847-2.64 2.087-3.488 3.684L5.217 31.714c-1.757 3.306-1.6 7.36.418 10.844 2.018 3.483 5.455 5.634 9.194 5.755l25.694.828c.114.004.225.005.338.005 3.35 0 6.238-1.668 7.922-4.577a10.519 10.519 0 001.393-5.23c.007-2.005-.55-4.041-1.609-5.888L34.997 9.803c-1.064-1.856-2.55-3.37-4.295-4.376A10.525 10.525 0 0025.458 4m0-4c4.942 0 10.035 2.628 13.01 7.812L52.036 31.46c5.657 9.86-.539 21.686-11.176 21.686-.155 0-.31-.002-.467-.007L14.7 52.31C3.777 51.958-3.444 39.49 1.685 29.837l12.124-22.82C16.306 2.32 20.816 0 25.457 0z"
              fill="#4343ff"
              transform="rotate(146 28.433 144.77)"
            />
          </g>
        </g>
        <g
          filter="url(#h)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g
            data-name="Polygon 19"
            fill="none"
            strokeLinejoin="round"
            opacity={0.999}
          >
            <path
              d="M87.682 13.727a9 9 0 0115.64 0l80.017 140.455a9 9 0 01-7.82 13.455H15.485a9 9 0 01-7.82-13.455z"
              transform="rotate(137 172.338 179.885)"
            />
            <path
              d="M95.502 13.182c-.869 0-1.718.217-2.457.63a4.91 4.91 0 00-1.887 1.895L11.14 156.162c-1.286 2.256-.413 4.24.021 4.987.435.748 1.726 2.488 4.323 2.488H175.52c2.597 0 3.888-1.74 4.323-2.488.435-.748 1.307-2.73.021-4.987L99.847 15.707a4.91 4.91 0 00-1.888-1.896 5.053 5.053 0 00-2.457-.63m0-4c3.047 0 6.094 1.516 7.82 4.546l80.017 140.455c3.418 6-.915 13.455-7.82 13.455H15.485c-6.905 0-11.238-7.455-7.82-13.455L87.682 13.727c1.726-3.03 4.773-4.545 7.82-4.545z"
              fill="#ff4a3c"
              transform="rotate(137 172.338 179.885)"
            />
          </g>
        </g>
        <g
          filter="url(#i)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g
            data-name="Polygon 20"
            fill="none"
            strokeLinejoin="round"
            opacity={0.999}
          >
            <path
              d="M68.042 13.753a9 9 0 0115.646 0l60.391 106.169a9 9 0 01-7.823 13.45H15.474a9 9 0 01-7.823-13.45z"
              transform="rotate(60 278.245 481.934)"
            />
            <path
              d="M75.865 13.203c-.87 0-1.72.218-2.458.63a4.91 4.91 0 00-1.888 1.898L11.127 121.9c-1.283 2.256-.41 4.238.024 4.986.435.747 1.727 2.486 4.323 2.486h120.783c2.595 0 3.887-1.739 4.322-2.486.434-.748 1.307-2.73.024-4.986L80.21 15.73a4.91 4.91 0 00-1.887-1.897 5.053 5.053 0 00-2.459-.63m0-4c3.049 0 6.097 1.516 7.823 4.55l60.391 106.169c3.413 6-.92 13.45-7.822 13.45H15.474c-6.903 0-11.236-7.45-7.823-13.45l60.391-106.17c1.726-3.033 4.774-4.55 7.823-4.55z"
              fill="#3fe0e0"
              transform="rotate(60 278.245 481.934)"
            />
          </g>
        </g>
        <g
          filter="url(#j)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g data-name="Path 473" fill="none" opacity={0.999}>
            <path
              d="M19.9 10.113c7.43-13.98 27.225-13.342 35.538 1.146L74.994 45.34c8.272 14.416-1.037 31.75-16.779 31.243l-37.03-1.194C5.444 74.881-4.963 56.912 2.428 43z"
              transform="rotate(-60 191.275 29.662)"
            />
            <path
              d="M36.689 4c-2.698 0-5.31.685-7.552 1.98-2.403 1.39-4.322 3.411-5.703 6.01L5.96 44.877c-1.442 2.714-2.094 5.67-1.938 8.784.148 2.948 1.037 5.907 2.573 8.558 1.535 2.65 3.658 4.892 6.141 6.486 2.623 1.683 5.509 2.587 8.578 2.686l37.03 1.194c.184.006.362.009.544.009 2.802 0 5.384-.696 7.672-2.068 2.145-1.286 3.968-3.157 5.273-5.409 1.457-2.516 2.233-5.426 2.244-8.415.011-3.2-.871-6.44-2.552-9.371L51.969 13.25c-1.69-2.945-4.055-5.35-6.84-6.957-2.6-1.5-5.52-2.293-8.44-2.293m0-4c7.123 0 14.462 3.788 18.75 11.26l19.555 34.08c8.153 14.209-.776 31.254-16.106 31.254-.224 0-.446-.004-.673-.011l-37.03-1.194C5.444 74.881-4.963 56.912 2.428 43l17.474-32.887C23.5 3.343 29.999 0 36.689 0z"
              fill="#ffcd43"
              transform="rotate(-60 191.275 29.662)"
            />
          </g>
        </g>
        <g
          filter="url(#k)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g data-name="Path 474" fill="none" opacity={0.999}>
            <path
              d="M40.9 20.783c15.265-28.73 55.944-27.418 73.028 2.355l40.187 70.036c17 29.625-2.132 65.247-34.481 64.2l-76.1-2.454C11.186 153.881-10.2 116.953 4.99 88.366z"
              transform="matrix(.97 -.26 .26 .97 328.06 66.85)"
            />
            <path
              d="M75.396 4c-6.284 0-12.38 1.603-17.63 4.637a33.74 33.74 0 00-7.411 5.804c-2.302 2.389-4.295 5.154-5.924 8.219L8.522 90.243a38.565 38.565 0 00-3.654 9.99 39.421 39.421 0 00-.817 10.25c.336 6.727 2.359 13.467 5.85 19.492 3.489 6.024 8.328 11.13 13.992 14.765a39.396 39.396 0 009.294 4.387 38.54 38.54 0 0010.478 1.8l76.096 2.453c.423.013.834.02 1.255.02 3.307 0 6.537-.43 9.601-1.279a33.7 33.7 0 008.335-3.572c5.02-3.01 9.279-7.373 12.317-12.619 3.363-5.808 5.154-12.514 5.18-19.393a41.152 41.152 0 00-1.381-10.676c-.974-3.679-2.462-7.278-4.424-10.696l-40.187-70.037c-1.972-3.436-4.34-6.55-7.041-9.258a41.162 41.162 0 00-8.568-6.594C88.864 5.824 82.138 4 75.396 4m0-4c14.638 0 29.72 7.784 38.53 23.137l40.187 70.037c16.755 29.198-1.594 64.226-33.097 64.226-.46 0-.918-.007-1.384-.022l-76.096-2.454c-32.35-1.043-53.736-37.97-38.546-66.558l35.909-67.583C48.292 6.869 61.649 0 75.396 0z"
              fill="#ffcd43"
              transform="matrix(.97 -.26 .26 .97 328.06 66.85)"
            />
          </g>
        </g>
        <g
          filter="url(#l)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g
            data-name="Polygon 21"
            fill="none"
            strokeLinejoin="round"
            opacity={0.999}
          >
            <path
              d="M78.472 3.362a9 9 0 0110.026 0l72.68 48.738a9 9 0 013.477 10.463l-27.457 78a9 9 0 01-8.489 6.012H38.262a9 9 0 01-8.489-6.012l-27.457-78A9 9 0 015.792 52.1z"
              transform="rotate(113 224.953 261.43)"
            />
            <path
              d="M83.485 5.837c-.995 0-1.958.293-2.785.847L8.02 55.426A4.999 4.999 0 006.09 61.24l27.456 78.001a5.007 5.007 0 004.717 3.34h90.446a5.007 5.007 0 004.717-3.34L160.88 61.24a4.999 4.999 0 00-1.931-5.813L86.27 6.684a4.983 4.983 0 00-2.785-.847m0-4a8.99 8.99 0 015.013 1.525l72.68 48.742a9 9 0 013.476 10.463l-27.456 78.001a9 9 0 01-8.49 6.012H38.262a9 9 0 01-8.49-6.012l-27.456-78a9 9 0 013.476-10.464l72.68-48.742a8.99 8.99 0 015.013-1.525z"
              fill="#3f7de0"
              transform="rotate(113 224.953 261.43)"
            />
          </g>
        </g>
        <g
          filter="url(#m)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g
            data-name="Polygon 22"
            fill="none"
            strokeLinejoin="round"
            opacity={0.999}
          >
            <path
              d="M65.835 1.981a9 9 0 017.994 0l48.147 23.867a9 9 0 014.789 6.115l11.985 54.051a9 9 0 01-1.672 7.46l-33.466 43.2a9 9 0 01-7.115 3.488H43.166a9 9 0 01-7.115-3.488L2.586 93.475a9 9 0 01-1.672-7.46L12.9 31.963a9 9 0 014.789-6.115z"
              transform="rotate(60 49.025 111.853)"
            />
            <path
              d="M69.832 5.045c-.766 0-1.534.18-2.22.52L19.465 29.432a4.978 4.978 0 00-2.66 3.397L4.818 86.88a4.986 4.986 0 00.93 4.145l33.465 43.196a5.033 5.033 0 003.952 1.938h53.332a5.033 5.033 0 003.953-1.938l33.465-43.196a4.986 4.986 0 00.93-4.145L122.86 32.83a4.978 4.978 0 00-2.66-3.398L72.052 5.565a5.026 5.026 0 00-2.22-.52m0-4c1.368 0 2.737.312 3.996.936l48.147 23.867a9 9 0 014.79 6.115l11.984 54.051a9 9 0 01-1.672 7.46l-33.465 43.197a9 9 0 01-7.115 3.488H43.166a9 9 0 01-7.114-3.488L2.586 93.475a9 9 0 01-1.672-7.46L12.9 31.962a9 9 0 014.79-6.115L65.834 1.981a8.996 8.996 0 013.997-.936z"
              fill="#3fe0e0"
              transform="rotate(60 49.025 111.853)"
            />
          </g>
        </g>
        <g
          filter="url(#n)"
          transform="translate(-420.223 -523.527) translate(420.22 523.53)"
        >
          <g data-name="Path 475" fill="none" opacity={0.999}>
            <path
              d="M13.81 7.017c5.154-9.7 18.889-9.258 24.658.8L52.037 31.46c5.74 10-.72 22.031-11.643 21.679L14.7 52.31C3.777 51.958-3.444 39.489 1.685 29.837z"
              transform="rotate(-34 310.452 -761.176)"
            />
            <path
              d="M25.458 4C23.8 4 22.2 4.419 20.83 5.21c-1.466.847-2.64 2.087-3.488 3.684L5.217 31.714c-1.757 3.306-1.6 7.36.418 10.844 2.018 3.483 5.455 5.634 9.194 5.755l25.694.828c.114.004.225.005.338.005 3.35 0 6.238-1.668 7.922-4.577a10.519 10.519 0 001.393-5.23c.007-2.005-.55-4.041-1.609-5.888L34.997 9.803c-1.064-1.856-2.55-3.37-4.295-4.376A10.525 10.525 0 0025.458 4m0-4c4.942 0 10.035 2.628 13.01 7.812L52.036 31.46c5.657 9.86-.539 21.686-11.176 21.686-.155 0-.31-.002-.467-.007L14.7 52.31C3.777 51.958-3.444 39.49 1.685 29.837l12.124-22.82C16.306 2.32 20.816 0 25.457 0z"
              fill="#4343ff"
              transform="rotate(-34 310.452 -761.176)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default UseCasesImage
